import { ref, computed } from "vue";
import { searchItems } from "@/services/items";
import { useStore } from "vuex";

const logs = ref([]);

export const useMenuLogs = ({ id = null }) => {
    const store = useStore();

    const getMenuLogs = async () => {
        store.dispatch("setLoading", true);
        try {
            logs.value = await searchItems({ table: 'menu_logs', key: 'menuId', value: id });
            logs.value = logs.value.sort((a, b) => {
                return new Date(b.timestamp) - new Date(a.timestamp);
            })
        } catch (error) {
            store.dispatch("setError", error);
            console.error({ error })
        }
        store.dispatch("setLoading", false);
    };

    return {
        logs: computed(() => logs.value),
        getMenuLogs,
    }
}