<template>
  <menu-header title="Menus" :add="false"></menu-header>
  <main>
    <div class="max-w-7xl mx-auto px-4">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Timestamp
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Logs
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white" v-for="log in logs" :key="log.id">
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {{ log.timestamp }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ log.log }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-end">
          <router-link
            to="/"
            tag="button"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { useRoute } from "vue-router";
import MenuHeader from "@/components/MenuHeader";
import { useMenuLogs } from "./hook";

export default {
  name: "MenuLogs",
  components: {
    MenuHeader,
  },
  setup() {
    const route = useRoute();

    const { logs, getMenuLogs } = useMenuLogs({
      id: route.params.id,
    });

    getMenuLogs();

    return {
      logs,
    };
  },
};
</script>

